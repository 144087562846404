<template>
	<Main :archive="archive" :selectedYear="selectedYear"></Main>
</template>

<script>
import Main from './ui/Main.vue';

export default {
	name: 'Activities',
	props: {
		archive: {
			type: Boolean,
			default: () => false
		},
		selectedYear: {
			type: Number,
			default: () => 2022
		}
	},
	components: {
		Main
	}
};
</script>
