<template>
	<div class="flex flex-row flex-wrap justify-start items-start sm:w-auto h-full gap-5" :class="{ 'w-screen px-4 py-6 sm:px-6 sm:py-12': !archive }">
		<!-- Заголовок -->
		<h1 v-if="!archive" class="flex w-full text-xl h-6 mb-4 sm:mb-7 font-extrabold sm:text-2xl">Активность членов ОС по НОК</h1>
		<!-- Регион -->
		<div v-if="!archive" class="flex flex-col justify-between w-full px-5 py-8 sm:w-7/12 sm:h-40 sm:mr-4 white-round-box">
			<p class="flex h-5 my-2 text-md text-gray-400 font-bold">Регион</p>
			<p class="flex h-5 my-2 text-lg text-gray-900 font-bold">
				{{ getAuthUser.regionName }}
			</p>
		</div>
		<!-- Мониторинг, инструкция -->
		<div v-if="!archive" class="doc-box hidden sm:flex flex-col flex-grow justify-between white-round-box w-4/12 h-40">
			<a class="flex text-sm text-blue-500 underline" href="https://static-0.minzdrav.gov.ru/system/attachments/attaches/000/041/812/original/Мониторинг__НОК.pdf?1544167048" target="_blank">
				О мониторинге проведения независимой оценки качества условий оказания услуг медицинскими организациями в субъектах Российской Федерации
			</a>
			<a class="flex text-sm text-gray-500 underline" :href="calcHref" target="_blank">
				<span v-show="chairman">Инструкция пользователя "Председателя ОС"</span>
				<span v-show="secretary">Инструкция пользователя "Секретарь ОС"</span>
			</a>
		</div>
		<!-- Таблица -->
		<ActivityTable :archive="archive" :selectedYear="selectedYear" />
	</div>
</template>

<script>
// Глобальные данные стора
import { getters } from '@/store/store';

import ActivityTable from './ActivityTable.vue';

export default {
	name: 'Main',
	props: {
		archive: {
			type: Boolean,
			default: () => false
		},
		selectedYear: {
			type: Number,
			default: () => 2022
		}
	},
	components: { ActivityTable },
	computed: {
		...getters,
		chairman() {
			return this.getAuthUser.roles.indexOf('OS_Chairman') !== -1;
		},
		secretary() {
			return this.getAuthUser.roles.indexOf('OS_Secretary') !== -1;
		},
		calcHref() {
			return this.chairman
				? 'https://nok.minzdrav.gov.ru/docs/Оценка ОС. Инструкция пользователя председатель ОС.pdf'
				: 'https://nok.minzdrav.gov.ru/docs/Оценка ОС. Инструкция пользователя секретарь ОС.pdf';
		}
	}
};
</script>

<style scoped>
.white-round-box {
	@apply sm:px-10 sm:py-8 rounded-lg bg-white filter drop-shadow-default;
}
</style>
